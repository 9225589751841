import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'
import Blogik, {
  BlogConsumer,
  BlogButton,
  BlogFilter,
} from 'components/shared/Blogik'

// Components
import Image from 'components/shared/ImageGatsby'

// SVG
import ArrowRightWhite from 'img/arrow-right-white.svg'

const StyledImage = styled(Image)`
  @media (max-width: 767px) {
    max-height: 263px;
  }

  @media (min-width: 768px) {
    max-height: 357px;
  }

  @media (min-width: 1200px) {
    min-height: 488px;
    max-height: 488px;
  }
`
const ExcerptWrapper = styled.div`
  bottom: 100px;

  @media (max-width: 767px) {
    bottom: 0;
  }
`

const ExcerptContent = styled.div`
  min-height: 256px;
  background-color: ${(props) => props.theme.color.face.light};
  border: 1px solid ${(props) => props.theme.color.text.secondary};
`

const Excerpt = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[16]};
    color: ${(props) => props.theme.color.text.dark};
    line-height: ${(props) => props.theme.font.size[20]};
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1200px) {
      -webkit-line-clamp: 3;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  min-width: 200px;
  max-width: 200px;

  & a {
    &:after {
      transition: all 0.2s ease-in-out;
      padding-left: 10px;
      content: url(${ArrowRightWhite});
    }
    &:hover:after {
      padding-left: 20px;
    }
  }

  @media (max-width: 991px) {
    max-width: 180px;
    min-width: 180px;
  }
`

const BlogWrapper = styled.div`
  @media (max-width: 767px) {
    border-bottom: 1px solid ${(props) => props.theme.color.text.secondary};
  }
`

const ProjectButtonWrapper = styled.div`
  @media (min-width: 767px) {
    height: 100%;
  }

  @media (max-width: 1199px) {
    margin-left: -30px;
    margin-top: -30px;
    position: relative;
    top: -30px;
  }
`

interface VacaturesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Vacancies
  location: any
}

const Vacatures: React.FC<VacaturesProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 my-5">
      <div className="row justify-content-center">
        <div className="col-lg-9 col-12 d-flex flex-column">
          <Blogik
            postType="vacatures"
            settings={{
              id: 'blog',
              limit: Number(fields.limit),
            }}
          >
            <div>
              <VacatureFilters />
              <VacatureGrid fields={fields} />
            </div>
          </Blogik>
        </div>
      </div>
    </div>
  </section>
)

const StyledBlogFilter = styled(BlogFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected ? props.theme.font.weight.l : props.theme.font.weight.s};
`

interface VacaturesFiltersProps {}

interface VacatureFilterProps {
  node: {
    id: string
    name: string
  }
}

const VacatureFilters: React.FC<VacaturesFiltersProps> = () => (
  <BlogConsumer>
    {({ categories, isSelected }: any) => (
      <div className="container d-flex">
        {categories.map((category: VacatureFilterProps) => (
          <StyledBlogFilter
            key={category.node.id}
            id={category.node.id}
            selected={isSelected(category.node.id)}
            className="mr-3 d-none"
          >
            {category.node.name}
          </StyledBlogFilter>
        ))}
      </div>
    )}
  </BlogConsumer>
)

interface VacatureGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Vacancies
}

const VacatureGrid: React.FC<VacatureGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts, showMoreButton }: any) => (
      <div className="container">
        <div className="row">
          {posts.map((post: VacatureGridPostProps) => (
            <div key={post.node.id}>
              <VacatureGridPost node={post.node} vacatureFields={fields} />
            </div>
          ))}
        </div>

        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadMoreText}</BlogButton>
            </ButtonDefault>
          </div>
        )}
      </div>
    )}
  </BlogConsumer>
)

interface VacatureGridPostProps {
  // eslint-disable-next-line
  vacatureFields: GatsbyTypes.WpPage_Flexcontent_Flex_Vacancies
  node: {
    id: string
    title: string
    uri: string
  }
}

const VacatureGridPost: React.FC<VacatureGridPostProps> = ({
  node,
  vacatureFields,
}) => (
  <BlogWrapper>
    <StyledImage image={node.vacancyDetail.thumbnail} loading="lazy" />
    <div className="d-flex justify-content-center">
      <ExcerptWrapper className="row position-relative align-items-center justify-content-between mx-lg-5 mx-0">
        <ExcerptContent className="p-lg-5 p-4 col-md-10 col-12">
          <Excerpt content={node.vacancyDetail.excerpt} />
          <StyledButtonDefault to={node.uri} className="mt-4">
            bekijk vacature
          </StyledButtonDefault>
        </ExcerptContent>
      </ExcerptWrapper>
    </div>
  </BlogWrapper>
)

export default Vacatures
